@use "@angular/material" as mat;

$flag-icon-css-path: "~flag-icon-css/flags" !default;

@import "~flag-icon-css/sass/flag-icon";

// $image_path: '~material-design-lite/src/images' !default;
// @import "~material-design-lite/src/material-design-lite";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$TOM-Web-primary: mat.define-palette(mat.$grey-palette, 500);
$TOM-Web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$TOM-Web-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$TOM-Web-theme: mat.define-light-theme(
  $TOM-Web-primary,
  $TOM-Web-accent,
  $TOM-Web-warn
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($TOM-Web-theme);
.mat-form-field-underline {
  background-color: #9e9e9e;
}

.mat-form-field-empty.mat-form-field-label {
  /* B2 */

  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;
  white-space: normal;
  /* or 169% */

  /* Form-text */

  color: #8c8c8c;
}

.input.mat-input-element {
  font-weight: 600;
  color: #494949;
}

@font-face {
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  src: url("~src/assets/fonts/avenir-medium.eot") format("embedded-opentype"),
    url("~src/assets/fonts/avenir-medium.ttf") format("truetype"),
    url("~src/assets/fonts/avenir-medium.woff") format("woff"),
    url("~src/assets/fonts/avenir-medium.woff2") format("woff2");
}

@font-face {
  font-family: Avenir;
  font-style: normal;
  font-weight: 600;
  src: url("~src/assets/fonts/avenir-heavy.eot") format("embedded-opentype"),
    url("~src/assets/fonts/avenir-heavy.ttf") format("truetype"),
    url("~src/assets/fonts/avenir-heavy.woff") format("woff"),
    url("~src/assets/fonts/avenir-heavy.woff2") format("woff2");
}

@font-face {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  src: url("~src/assets/fonts/avenir-bold.eot") format("embedded-opentype"),
    url("~src/assets/fonts/avenir-bold.ttf") format("truetype"),
    url("~src/assets/fonts/avenir-bold.woff") format("woff"),
    url("~src/assets/fonts/avenir-bold.woff2") format("woff2");
}

@font-face {
  font-family: Avenir;
  font-weight: 100;
  src: url("~src/assets/fonts/avenir-light.otf") format("opentype");
}

@font-face {
  font-family: Avenir;
  font-weight: 200;
  src: url("~src/assets/fonts/avenir-book.ttf");
}

.margin-auto {
  margin: auto;
}

.contact-us {
  .mat-form-field-label {
    color: rgba(160, 160, 160, 0.8) !important;
  }
}

*,
body {
  box-sizing: border-box;
  margin: 0;
  font-family: Avenir;
}

div,
p,
button {
  font-family: Avenir;
}

a {
  color: #00a3d3;
  text-decoration: underline;
  cursor: pointer;
}

button {
  border: none;
  cursor: pointer;
}

@mixin button-focus {
  width: 95%;
  height: 95%;
}

@mixin cta-button {
  font-family: Avenir;
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 149px;
  height: 42px;
  margin: auto;
  background-color: #00a3d3;
  border-radius: 2px;
  cursor: pointer;
}

@mixin cta-button-white {
  font-family: Avenir;
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00a3d3;
  width: 149px;
  height: 42px;
  margin: auto;
  background-color: white;
  border-radius: 2px;
  border: 2px solid #00a3d3;
  cursor: pointer;
}

@mixin cta-button-transparent {
  @include cta-button();
  border: solid 2px #ffffff;
  background-color: transparent;
  text-decoration: none;
}

select {
  -webkit-appearance: none;
  background-color: white;
  height: 50px;
  font-size: 16px;
  background-image: url("/assets/images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 95%;
  border: none;
  border-bottom: 2px solid #a0a0a0;
  width: 100%;
  font-weight: 900;
}

.flex-box-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktop .limit-width {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

input {
  width: 100%; //   border: none;
  //   border-bottom: 2px solid #a0a0a0;
  padding-left: 5px; //   color: #a0a0a0;
  font-size: 16px;
  padding-left: 5px;
}

button {
  height: 50px;
  width: 100%;
  font-size: 16px;
}

button:disabled {
  background-color: gray !important;
  border-color: transparent !important;
  color: white !important;
}

.subscribe-button:disabled {
  background-color: white !important;
  color: #494949 !important;
}

.desktop button {
  width: 299px;
  cursor: pointer;
}

.border {
  margin-right: 20px;
  margin-left: 20px;
  height: 1px;
  opacity: 0.2;
  border: 1px solid #979797;
}

.desktop .border {
  display: none;
}

.btn-blue {
  color: white;
  background-color: #00a3d3;
  transition: background-color 200ms linear;
  line-height: 17px;
  font-weight: 600;
  font-size: 17px;
  border-radius: 2px;
  border: 2px solid transparent;
}

.btn-blue-margin {
  margin-top: 15px;
}

// .btn-blue:hover {
// 	color: #00a3d3;
// 	background-color: white;
// 	border-color: #00a3d3;
// 	line-height: 48px;
// }

.m-a {
  margin: auto;
}

.btn-text-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00a3d3;
  background-color: transparent;
  border: 2px solid #00a3d3;
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  line-height: 17px;
  font-size: 17px;
  transition: background-color 200ms linear;
}

// .btn-text-blue:hover {
// 	color: white;
// 	background-color: #00a3d3;
// 	border-color: transparent;
// }

.btn-text-green {
  color: #88c54f;
  background-color: transparent;
  border: 1px solid #88c54f;
  line-height: 48px;
  transition: background-color 200ms linear;

  &.opposite {
    color: white;
    font-weight: 900;
    background-color: #88c54f !important;
    border: 2px solid transparent !important;
    line-height: 48px;
  }
}

.btn-text-green:hover {
  color: white;
  background-color: #88c54f;
  border-color: transparent;
}

.btn-text-green-like {
  color: #88c54f;
  background-color: transparent;
  border: 1px solid #88c54f;
  line-height: 52px;
  text-align: center;
}

.btn-text-orange {
  color: #f15d31;
  background-color: transparent;
  border: 1px solid #f15d31;
  line-height: 52px;
  transition: background-color 200ms linear;
}

.btn-text-orange:hover {
  color: white;
  background-color: #f15d31;
  border-color: transparent;
}

.btn-green {
  color: white;
  font-weight: 900;
  background-color: #88c54f;
  border: 2px solid transparent;
  line-height: 48px;
  transition: background-color 200ms linear;
}

// .btn-green:hover {
// 	color: #88c54f;
// 	background-color: white;
// 	border-color: #88c54f;
// 	line-height: 48px;
// }

.btn-transparent {
  border: 2px solid #00a3d3;
  color: #00a3d3;
  background-color: transparent;
  line-height: 50px;
  transition: background-color 200ms linear;
}

.btn-transparent:hover {
  border-color: transparent;
  color: white;
  background-color: #00a3d3;
  line-height: 50px;
}

.text-center {
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: 300;
}

.bold {
  font-weight: 600;
}

.heavy {
  font-weight: 600;
}

.black {
  color: black;
}

.round {
  border-radius: 50%;
}

.text-blue {
  color: #00a3d3;
}

.text-green {
  color: #88c54f;
}

.text-orange {
  color: #f15d31;
}

.text-black {
  color: #494949;
}

.text-gray {
  color: #a0a0a0;
}

.header-card {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: #4d4d4d;
}

.text-section {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #4d4d4d;
}

.text-header {
  font-weight: 600;
  font-size: 26px;
  line-height: 29px;
  color: #4d4d4d;
}

.text-body {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
}

.text-body-bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  //color: #4d4d4d;
}

.border-orange {
  border-color: #f15d31;
}

.border-green {
  border-color: #88c54f;
}

.border-blue {
  border-color: #00a3d3;
}

.pointer,
.links {
  cursor: pointer;
}

.mat-form-field {
  width: 100%;
}

.mat-form-field-infix {
  width: 100% !important;
}

*:focus {
  outline: none;
}

.remove-video-padding {
  padding: 0;
}

.flag-icon {
  left: 65%;
  top: 76%;
  height: 30px;
  width: 30px;
}

.sb-wrapper {
  width: inherit !important;
}

.ng-fa-icon {
  margin-top: 6px;
}

.cursor-default {
  cursor: default;
}

// OOCSS flex rules.
.flex-row {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.self-center {
  align-self: center;
}

.items-center {
  align-items: center;
}

.ml-10 {
  margin-left: 10px;
}

//OOCSS layout rules
.pr-2 {
  padding-right: 0.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 2rem;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.makers-popup {
  position: fixed;
  margin-left: auto;
}

.w-100 {
  width: 100%;
}

._text-center {
  ._menu-reset-btn._menu-btn.material-icons {
    display: none !important;
  }
}

.mat-dialog-container {
  padding: 0px !important;
}

i._access-icon.material-icons._access {
  bottom: 115px !important;
}

*:focus {
  outline-color: transparent;
}

* {
  outline-color: transparent;
}

// fix alignment of slider slides to the left
app-items-slider {
  .slick-track {
    width: 100px;
    margin-right: auto !important;
    margin-left: unset !important;
  }
}

//#cdk-overlay-0 #cdk-overlay-1 {
//  display: none;
//}
//.cdk-overlay-container{
//  display: none;
//}

form.search-form {
  border: 1px solid rgba(37, 37, 37, 0.12);
  box-sizing: border-box;
  height: 50px;
  margin-top: 10px;
  display: inline-flex;
  width: 200px;
  border-radius: 5px;
}

form input.filter-search-input {
  display: inline-block;
  height: 100%;
  width: fit-content;
  margin-left: 25px;
}

@media screen and (max-width: 600px) {
  form.search-form {
    width: 100%;
  }
}

.B2-dark-text {
  /* B2 */
  font-weight: 300;
  font-size: 15px;
  line-height: 27px;
  /* or 169% */

  /* medium grey */

  color: #4d4d4d;
}

.B2-medium-text {
  /* B2 */
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */

  /* medium grey */

  color: #8c8c8c;
}

.B4-text {
  /* B4 */
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  color: #8c8c8c;
}

H4-text {
  /* H4 */
  font-weight: 500;
  font-size: 20px;
  line-height: 0px;
  /* or 0% */

  letter-spacing: -0.02em;

  /* medium grey */

  color: #3f4043;
}

.question-box {
  background-color: #fff;
  border-radius: 2px;
  height: 60px;
  text-align: start;
  padding: 16px 9px 15px 14px;
}

.answer-text {
}

.dropdown-option {
}

::ng-deep .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
  font-size: 15px;
  line-height: 27px;
  /* or 169% */

  /* Dark grey */

  color: #4d4d4d;
}

// tooltip styling.
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "~tippy.js/animations/scale.css";
.tippy-box[data-theme~="documentation"] {
  background-color: black;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px;
  text-align: left;
}

.tippy-box[data-theme~="documentation"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: black;
}

.tippy-box[data-theme~="documentation"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: black;
}

.tippy-box[data-theme~="documentation"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: black;
}

.tippy-box[data-theme~="documentation"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: black;
}
